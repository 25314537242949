import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { SaveButton } from "components/Button";
import Image from "components/Image";
import React, { ReactElement, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { MyStyles } from "style/theme";
import { ShopApi } from "system/ApiService";
import { IsEngineer2User, IsManagerUser } from "system/Helper";
import { useQtyDispatch } from "system/QuantityContext";
import { CheckUnitPrice, INIT_CHECK_PRICE, Item } from "system/types";
import { useUserState } from "system/UserContext";
import { GreenCheckIcon, RedNotInterestedIcon } from "../custom/Icon";
import Manager2Component from "./Manager2Component";
import UserComponent from "./UserComponent";

interface MaterialBoxProps {
  data: Item;
  mobile: boolean;
  kind: string;
}

function MaterialBox({ data, mobile, kind }: MaterialBoxProps): ReactElement {
  const classes = MyStyles();
  const history = useHistory();
  const user = useUserState();
  const qtyDispatch = useQtyDispatch();
  const [quantity, setQuantity] = useState(1);
  const [ERPprice, setERPPrice] = useState<number>(data.price);
  const [checkPrice, setCheckPrice] = useState<CheckUnitPrice>(INIT_CHECK_PRICE);

  useMemo(() => {
    setCheckPrice({
      ...checkPrice,
      ItemNumber: data.productNumber,
      Customer: user.custAccount,
      PriceDate: new Date().toISOString().split("T")[0],
      Currency: user.currencyCode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    history.push({ pathname: `/shop/${kind}/view/${data.productNumber}` });
  };

  const addItem = () => {
    setQuantity(quantity + 1);
    ShopApi.CheckUnitPrice({ ...checkPrice, Quantity: (quantity + 1).toString() })
      .then((res) => {
        setERPPrice(res.unitPrice);
      })
      .catch(() => {});
  };

  const deleteItem = () => {
    setQuantity(quantity === 1 ? 1 : quantity - 1);
    ShopApi.CheckUnitPrice({ ...checkPrice, Quantity: (quantity === 1 ? 1 : quantity - 1).toString() })
      .then((res) => {
        setERPPrice(res.unitPrice);
      })
      .catch(() => {});
  };

  const onChange = (event: any) => {
    const { value } = event.target;
    let num: number | undefined = parseInt(value);
    if (num >= 1) {
      num = num === 1 ? 1 : num;
    } else {
      num = 1;
    }
    setQuantity(num);
  };

  const onHandel = (event: React.KeyboardEvent<HTMLDivElement>) => {
    ShopApi.CheckUnitPrice({ ...checkPrice, Quantity: quantity.toString() })
      .then((res) => {
        setERPPrice(res.unitPrice);
      })
      .catch(() => {});
  };

  const AddCart = () => {
    ShopApi.AddCart(data.productNumber, "MATERIAL", quantity, ERPprice, "")
      .then((res) => {
        if (res.result.id === 0) {
          alert("Already in cart");
        } else {
          // 장바구니 수량 업데이트
          ShopApi.GetMyCart().then((res) => {
            qtyDispatch({ qty: res.items.length });
          });
          setQuantity(quantity);
          alert("Successfully added to cart!");
        }
      })
      .catch((error) => {
        console.log("Error! Cannot add cart.");
      });
  };

  return (
    <div className={mobile === true ? classes.materialBoxMobile : classes.materialBox}>
      <div className="image" role="button" onClick={onClick}>
        <Image
          width={mobile === true ? 180 : 300}
          height={mobile === true ? 120 : 200}
          src={`/images/material/items/${data.image ? data.image : data.productNumber + ".jpg"}`}
          fallbackSrc="/images/NO_Image.png"
        ></Image>
      </div>
      <br />
      <Table className="pt-3">
        <tbody>
          <tr>
            <th style={{ width: "30%" }}>Name</th>
            <td>
              <Link to={`/shop/${kind}/view/${data.productNumber}`}>{data.productName}</Link>
            </td>
          </tr>
          <tr>
            <th>ERP code</th>
            <td>{data.productNumber}</td>
          </tr>
          {IsEngineer2User(user.role) ? null : (
            <tr>
              <th>Price</th>
              <td>
                {user.currencyCode}{" "}
                {ERPprice?.toFixed(2)
                  .toLocaleString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
            </tr>
          )}
          {IsManagerUser(user.role) ? (
            <tr>
              <th>Show / EOS</th>
              <td>
                {data.isShow ? <GreenCheckIcon /> : <RedNotInterestedIcon />}
                {data.stopped ? <RedNotInterestedIcon /> : <GreenCheckIcon />}
              </td>
            </tr>
          ) : null}
        </tbody>
        {IsEngineer2User(user.role) ? null : (
          <tfoot>
            <tr>
              <td colSpan={2}>
                {data.isOption ? (
                  "Select an option on the detail page."
                ) : (
                  <Grid container spacing={2} justify="center" alignItems="center">
                    <IconButton aria-label="minus quantity" onClick={deleteItem}>
                      <RemoveIcon />
                    </IconButton>
                    <TextField
                      className={classes.goodsQuantityMobile}
                      variant="outlined"
                      size="small"
                      onChange={onChange}
                      onKeyUp={onHandel}
                      value={quantity || ""}
                      inputProps={{ style: { textAlign: "center" } }}
                    ></TextField>
                    <IconButton aria-label="add quantity" onClick={addItem}>
                      <AddIcon />
                    </IconButton>
                    <UserComponent>
                      <Button
                        name="add-cart"
                        variant="contained"
                        onClick={AddCart}
                        disabled={!data.isShow}
                        color="primary"
                      >
                        Add Cart
                      </Button>
                    </UserComponent>
                    <Manager2Component>
                      <SaveButton disabled={true}>Add Cart</SaveButton>
                    </Manager2Component>
                  </Grid>
                )}
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </div>
  );
}

export default MaterialBox;
