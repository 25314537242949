import {
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Remove";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";
import Alert from "@material-ui/lab/Alert";
import { ActionButton, SaveButton } from "components/Button";
import AdminComponent from "components/Common/AdminComponent";
import Price from "components/Common/Price";
import UserComponent from "components/Common/UserComponent";
import ImageMagnifier from "components/ImageMagnifier";
import MaterialCautionView from "components/MaterialCautionView";
import ChangeMaterialModal from "page/Material/Manage/ChangeMaterialModal";
import React, { ChangeEvent, ReactElement, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { ErrorHandler, ItemApi, ShopApi } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { useQtyDispatch } from "system/QuantityContext";
import { useUserState } from "system/UserContext";
import { CheckUnitPrice, INIT_CHECK_PRICE, INIT_ITEM_INFO, ItemDetail, ObjType, OptionItem } from "system/types";

interface Params {
  materialCode: string;
}

function MaterialDetailPage(): ReactElement {
  const classes = MyStyles();
  const history = useHistory();
  const user = useUserState();
  const LoadingDispatch = useLoadingDispatch();
  const qtyDispatch = useQtyDispatch();
  const { materialCode } = useParams<Params>();
  const [quantity, setQuantity] = useState(1);
  const [data, setData] = useState<ItemDetail>();
  const [itemModal, setItemModal] = useState(false);
  const closeItemModal = () => setItemModal(false);
  const openItemModal = () => setItemModal(true);
  const [open, setOpen] = useState(false);
  const [ERPprice, setERPPrice] = useState<number>();
  const [checkPrice, setCheckPrice] = useState<CheckUnitPrice>(INIT_CHECK_PRICE);
  const [options, setOption] = useState<OptionItem[]>([]);
  const [opt, setOpt] = useState<string>();

  const GoToCart = () => {
    history.push({
      pathname: MyRoutes.cartMine,
    });
  };

  const GoToBack = () => {
    history.goBack();
  };

  const addItemOption = (name: string) => {
    setOption(options.map((x) => (x.optionItem === name ? { ...x, qty: x.qty + 1 } : x)));
  };

  const minusItemOption = (name: string) => {
    setOption(options.map((x) => (x.optionItem === name ? { ...x, qty: x.qty === 1 ? 1 : x.qty - 1 } : x)));
  };

  const removeOption = (name: string) => {
    setOption(options.filter((x) => x.optionItem !== name));
  };

  const onLoadData = React.useCallback(() => {
    const promise = ItemApi.GetItemByErpcode(materialCode, "MATERIAL", user.custAccount);

    LoadingDispatch({ type: "LOADING" });
    promise
      .then((res) => {
        setData(res);
        setCheckPrice({
          ...checkPrice,
          ItemNumber: res.productNumber,
          Customer: user.custAccount,
          PriceDate: new Date().toISOString().split("T")[0],
          Currency: user.currencyCode,
        });
        if (res.price === undefined) {
          setERPPrice(1.0);
        } else {
          setERPPrice(res.price);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log("Error! Cannot get spare parts detail." + msg);
        setData(INIT_ITEM_INFO);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LoadingDispatch, materialCode]);

  React.useEffect(() => onLoadData(), [onLoadData]);

  if (!data) {
    return <></>;
  } else if (data.productNumber !== "" && ERPprice !== undefined) {
    const addItem = () => {
      setQuantity(quantity + 1);
      ShopApi.CheckUnitPrice({ ...checkPrice, Quantity: (quantity + 1).toString() })
        .then((res) => {
          setERPPrice(res.unitPrice);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    };

    const deleteItem = () => {
      setQuantity(quantity === 1 ? 1 : quantity - 1);
      ShopApi.CheckUnitPrice({ ...checkPrice, Quantity: (quantity === 1 ? 1 : quantity - 1).toString() })
        .then((res) => {
          setERPPrice(res.unitPrice);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      let num: number | undefined = parseInt(value);
      if (num >= 1) {
        num = num === 1 ? 1 : num;
      } else {
        num = 1;
      }
      setQuantity(num);
    };

    const onHandel = (event: React.KeyboardEvent<HTMLDivElement>) => {
      ShopApi.CheckUnitPrice({ ...checkPrice, Quantity: quantity.toString() })
        .then((res) => {
          setERPPrice(res.unitPrice);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    };

    //상품 옵션을 선택하면 화면에 elements 추가 렌더링
    const selectOption = (event: React.ChangeEvent<{ value: unknown }>) => {
      const addOption = event.target.value as string;
      // eslint-disable-next-line array-callback-return
      const _options = options.filter((x) => {
        if (x.optionItem !== addOption) {
          setOpt(addOption);
          return x;
        } else {
          alert("Already selected this option.");
        }
      });
      setOption([..._options, { optionItem: addOption, qty: 1 }]);
    };

    const onChangeOptionQty = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, optionItem: string) => {
      const { value } = event.target;
      let num: number = parseInt(value);
      if (num >= 1) {
        num = num === 1 ? 1 : num;
      } else {
        num = 1;
      }
      setOption(options.map((x) => (x.optionItem === optionItem ? { ...x, qty: num } : x)));
    };

    const AddCart = () => {
      if (data.optionName) {
        for (const items of options) {
          ShopApi.AddCart(data.productNumber, "MATERIAL", items.qty, ERPprice, items.optionItem)
            .then((res) => {
              if (res.result.id === 0) {
                alert("Already in cart");
              } else {
                setOpen(true);
                setTimeout(() => {
                  setOpen(false);
                }, 5000);
              }
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              console.log("Error! Cannot add cart." + msg);
            });
        }
      } else {
        ShopApi.AddCart(data.productNumber, "MATERIAL", quantity, ERPprice, "")
          .then((res) => {
            if (res.result.id === 0) {
              alert("Already in cart");
            } else {
              setOpen(true);
              setTimeout(() => {
                setOpen(false);
              }, 5000);
              setQuantity(quantity);
            }
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log("Error! Cannot add cart." + msg);
          });
      }

      // 장바구니 수량 업데이트
      ShopApi.GetMyCart().then((res) => {
        qtyDispatch({ qty: res.items.length });
      });
    };

    const onUpdate = (data: ItemDetail, file: File | undefined) => {
      //데이터 저장
      const formData = new FormData();
      if (file && data.image) {
        formData.append("imageFile", file);
      }

      const obj: ObjType = { ...data };
      for (const key of Object.keys(data)) {
        const value = obj[key];
        if (value === undefined) continue;
        else if (Array.isArray(value)) {
          const ary = value.filter((x) => x);
          for (const i in ary) {
            formData.append(`${key}.Index`, i);
            formData.append(`${key}[${i}]`, ary[i]);
          }
        } else {
          formData.append(key, value);
        }
      }

      ItemApi.Upsert(data.productNumber, formData)
        .then((res) => {
          closeItemModal();
          alert("Success to Change");
          onLoadData();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log("Error! Cannot update item." + msg);
        });
    };

    return (
      <>
        <div className={classes.locationSection}>
          <AdminComponent>
            <div className="text-right">
              <ActionButton onClick={openItemModal}>Edit</ActionButton>
            </div>
            <ChangeMaterialModal
              maxWidth="sm"
              fullWidth
              open={itemModal}
              onClose={closeItemModal}
              data={data}
              onUpdate={onUpdate}
            ></ChangeMaterialModal>
          </AdminComponent>
        </div>
        <Grid container spacing={2} style={{ minWidth: "900px" }}>
          <Grid item xs={12}>
            <Typography variant="h3">&nbsp;{data.productName}</Typography>
            <Divider className={classes.blackHr} />
          </Grid>
          <Grid item xs={6}>
            <ImageMagnifier
              width={500}
              height={300}
              src={`/images/material/items/${data.image ? data.image : data.productNumber + ".jpg"}`}
              alt={data.productName}
              fallbackSrc="/images/NO_Image.png"
            ></ImageMagnifier>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.goods}>
              <dl>
                <dt>Code</dt>
                <dd className="erpcode">{data.productNumber}</dd>
              </dl>
              <dl>
                <dt>Price</dt>
                {data.price === undefined ? (
                  <dd style={{ color: "ff0000" }}>Contact to manager</dd>
                ) : (
                  <dd style={{ color: "ff0000" }}>
                    {ERPprice !== 0
                      ? ERPprice.toFixed(2)
                          .toLocaleString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0}{" "}
                    {user.currencyCode}
                  </dd>
                )}
              </dl>
              <dl>
                <dt>Compatible Product</dt>
                <dd>{data.compatibleProduct || <i>None</i>}</dd>
              </dl>
              <dl>
                <dt>Product Category</dt>
                <dd>{data.categoryName || <i>None</i>}</dd>
              </dl>
              {data.optionName ? (
                <>
                  <dl>
                    <dt>Option</dt>
                    <dd>
                      <Select onChange={selectOption} fullWidth value={opt}>
                        {data.optionName.split("|").map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </dd>
                  </dl>
                  <UserComponent>
                    {options &&
                      options.map((x, index) => (
                        <dl style={{ backgroundColor: "#f5f5f5" }}>
                          <dd>
                            <Grid item container justify="space-between">
                              <Grid item>{x.optionItem}</Grid>
                              <Grid item>
                                <IconButton aria-label="minus quantity" onClick={() => minusItemOption(x.optionItem)}>
                                  <RemoveIcon />
                                </IconButton>
                                <TextField
                                  className={classes.goodsQuantity}
                                  variant="outlined"
                                  onChange={(e) => onChangeOptionQty(e, x.optionItem)}
                                  value={x.qty || ""}
                                  inputProps={{ style: { textAlign: "center" } }}
                                ></TextField>
                                <IconButton aria-label="add quantity" onClick={() => addItemOption(x.optionItem)}>
                                  <AddIcon />
                                </IconButton>
                                <IconButton aria-label="remove option" onClick={() => removeOption(x.optionItem)}>
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </dd>
                        </dl>
                      ))}
                  </UserComponent>
                </>
              ) : (
                <UserComponent>
                  {data.price === undefined ? (
                    <></>
                  ) : (
                    <>
                      <dl>
                        <dt>Quantity</dt>
                        <dd>
                          <IconButton aria-label="minus quantity" onClick={deleteItem}>
                            <RemoveIcon />
                          </IconButton>
                          <TextField
                            className={classes.goodsQuantity}
                            variant="outlined"
                            size="small"
                            onChange={onChange}
                            onKeyUp={onHandel}
                            value={quantity || ""}
                            inputProps={{ style: { textAlign: "center" } }}
                          ></TextField>
                          <IconButton aria-label="add quantity" onClick={addItem}>
                            <AddIcon />
                          </IconButton>
                        </dd>
                      </dl>
                      <dl>
                        <dt>Total Price</dt>
                        <dd className={classes.goodsPrice}>
                          <Price price={ERPprice} quantity={quantity} priceUnit={user.currencyCode}></Price>
                        </dd>
                      </dl>
                    </>
                  )}
                </UserComponent>
              )}
            </div>
            <Grid item container xs={12} justify="center" spacing={1} className={`pt-3`}>
              <Grid item xs={12}>
                <Collapse in={open}>
                  <Alert
                    icon={false}
                    action={
                      <>
                        <Button color="secondary" onClick={GoToCart} size="small">
                          Go to Cart
                        </Button>
                        <Button color="secondary" onClick={GoToBack} size="small">
                          Continue Shopping
                        </Button>
                      </>
                    }
                  >
                    Successfully added to cart
                  </Alert>
                </Collapse>
              </Grid>
              {data.stopped ? (
                <Grid item xs={12}>
                  <Typography style={{ color: "red" }} variant="body2">
                    *&nbsp;This product has been discontinued.
                  </Typography>
                </Grid>
              ) : (
                <UserComponent>
                  <Grid item xs={6} className={classes.goodsButton}>
                    <SaveButton name="add-cart" variant="contained" onClick={AddCart} disabled={!data.isShow}>
                      Add Cart
                    </SaveButton>
                  </Grid>
                </UserComponent>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              <WarningTwoToneIcon className={classes.warning} />
              Caution
            </Typography>
            <Divider />
            <MaterialCautionView data={data.caution}></MaterialCautionView>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <Typography>There is No Item.</Typography>;
  }
}

export default MaterialDetailPage;
