import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import CSSummaryCard from "components/Common/CSSummaryCard";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MyRoutes } from "routes/Routes";
import { MyStyles } from "style/theme";
import { LetterNoticeApi } from "system/ApiService";
import { IsAdminUser } from "system/Helper";
import { useUserState } from "system/UserContext";
import { GetInitSearchResult, LetterNoticeItem, SearchResult } from "system/types";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

interface Params {
  categoryId: string;
}

interface LetterListPageParams {
  route: MyRoutes;
}

function LetterListPage({ route }: LetterListPageParams): ReactElement {
  const classes = MyStyles();
  let { categoryId } = useParams<Params>();
  const id = isNaN(parseInt(categoryId)) ? 0 : parseInt(categoryId);
  const user = useUserState();
  const isAdmin = IsAdminUser(user.role);
  const [lang, setLang] = useState<string>("En");
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState<SearchResult<LetterNoticeItem>>(GetInitSearchResult<LetterNoticeItem>([]));
  const [page, setPage] = useState<number>(data.page);
  const [keyword, setKeyword] = useState<string>("");
  const [orderBy, setOrderBy] = useState<boolean>(true);

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  };

  useEffect(() => {
    LetterNoticeApi.GetLetterNoticeListByCategoryId(id, 10, page, lang, keyword, orderBy)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        console.log("Error! Cannot get cs list.");
      });
  }, [id, page, lang, keyword, orderBy]);

  const onEdit = () => {
    history.push({
      pathname: `${route}/edit/${categoryId}`,
    });
  };

  const onChange = (lang: string) => {
    setLang(lang);
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setKeyword(event.target.value);
      setPage(1);
    }
  };

  const onClickExpanded = () => {
    setOrderBy(!orderBy);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Button
          variant={lang === "Kr" ? "outlined" : "contained"}
          color="primary"
          onClick={() => onChange("En")}
          style={{ marginRight: "8px" }}
        >
          English
        </Button>
        <Button
          variant={lang === "En" ? "outlined" : "contained"}
          color="primary"
          onClick={() => onChange("Kr")}
          style={{ marginRight: "8px" }}
        >
          Korean
        </Button>
      </Grid>
      <Grid item xs={6} className="text-right">
        <SearchIcon />
        <InputBase
          id="keyword"
          name="keyword"
          onKeyPress={onSearch}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
        />
        {isAdmin && (
          <Button onClick={onEdit} variant="contained" color="secondary">
            Write
          </Button>
        )}
      </Grid>
      <Grid item xs={12} container className={classes.csHeader}>
        <Grid item xs={8}>
          <Typography variant="h6" align="center">
            Title
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="center">
            Date
            <IconButton size="small" onClick={onClickExpanded}>
              {orderBy ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="center">
            Views
          </Typography>
        </Grid>
      </Grid>
      {data.list.length === 0 && (
        <Grid item xs={12} className="text-center mt-3">
          <Typography variant="subtitle1">No Posts found</Typography>
        </Grid>
      )}
      {data.list.map((x, index) => (
        <CSSummaryCard key={`cses${x.id}-${index}`} item={x} route={route} kind={"letternotice"}></CSSummaryCard>
      ))}
      <Grid item xs={12} className="d-flex justify-content-center">
        <Pagination
          color="primary"
          count={data.totalPage}
          defaultValue={1}
          page={page}
          showFirstButton
          showLastButton
          onChange={onPageChange}
        ></Pagination>
      </Grid>
    </Grid>
  );
}

export default LetterListPage;
